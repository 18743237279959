import React, { useRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Slider from "react-slick"
import Layout from "../components/layout"
import Seo from "../components/seo"

const TestimonialNextArrow = ({ className, onClick }) => (
  <div
    className={className}
    onClick={onClick}
    onKeyDown={onClick}
    role="button"
    tabIndex="0"
  >
    <svg fill="none" viewBox="0 0 26 20">
      <path
        fill="#fff"
        d="M2 11.225a1.225 1.225 0 010-2.45v2.45zm23.052-2.091a1.225 1.225 0 010 1.732l-7.795 7.795a1.225 1.225 0 11-1.732-1.732L22.455 10l-6.93-6.929a1.225 1.225 0 011.732-1.732l7.795 7.795zM2 8.775h22.186v2.45H2v-2.45z"
      />
    </svg>
  </div>
)

TestimonialNextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

const TestimonialPrevArrow = ({ className, onClick }) => (
  <div
    className={className}
    onClick={onClick}
    onKeyDown={onClick}
    role="button"
    tabIndex="0"
  >
    <svg fill="none" viewBox="0 0 26 20">
      <path
        fill="#fff"
        d="M24.186 11.225a1.225 1.225 0 100-2.45v2.45zM1.134 9.134a1.225 1.225 0 000 1.732l7.795 7.795a1.225 1.225 0 101.732-1.732L3.732 10l6.93-6.929a1.225 1.225 0 10-1.733-1.732L1.134 9.134zm23.052-.359H2v2.45h22.186v-2.45z"
      />
    </svg>
  </div>
)

TestimonialPrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

const settingsTestimonial = {
  dots: true,
  infinite: true,
  speed: 1100,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  nextArrow: <TestimonialNextArrow />,
  prevArrow: <TestimonialPrevArrow />,
}
const IndexPage = () => {
  const heroAnim = useRef()

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 1500)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (window.lottie) {
      window.lottie.loadAnimation({
        container: heroAnim.current,
        path: "/lottie/hero.json",
        renderer: "svg",
      })
    }
  }, [])

  useEffect(() => {
    Array.from(document.querySelectorAll(".wrap-text")).forEach(el => {
      el.innerHTML = el.textContent.replace(/\S/g, "<span>$&</span>")
    })
  }, [])

  return (
    <Layout>
      <Seo title="Home" />

      <section className="hero-main">
        <div className="hero-anim" ref={heroAnim} />
        <div className="container">
          <div className="offset-2">
            <div className={`content ${loading ? "" : "visible"}`}>
              <h1>
                <span className="wrap-text">
                  <span>Thoughtful</span>
                </span>
                <span>&nbsp;</span>
                <span className="wrap-text">
                  <span>and</span>
                </span>
                <span>&nbsp;</span>
                <span className="wrap-text">
                  <span>intelligent</span>
                </span>
                <span>&nbsp;</span>
                <span className="wrap-text">
                  <span>designs,</span>
                </span>
                <span>&nbsp;</span>
                <span className="wrap-text">
                  <span>made</span>
                </span>
                <span>&nbsp;</span>
                <span className="wrap-text">
                  <span>accessible.</span>
                </span>
              </h1>
              <p>Rooted ideas for businesses taking flight.</p>
            </div>
          </div>
        </div>

        <div className="hero-bottom-content">
          <div className="container">
            <div className="flex-row">
              <div className="col col-6">
                <div className="scroll-down">
                  <p>Scroll</p>
                </div>
              </div>
              <div className="col col-6">
                <div className="hero-social">
                  <a
                    href="https://www.behance.net/thepotatostudio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg width="22" height="13" viewBox="0 0 28 19" fill="none">
                      <path
                        fill="#fff"
                        d="M12.163 8.264c.602-.302 1.057-.64 1.369-1.004.554-.656.828-1.526.828-2.605 0-1.05-.274-1.946-.82-2.699C12.625.731 11.08.104 8.892.071H.233v17.68h8.075c.91 0 1.753-.079 2.532-.238.78-.162 1.454-.457 2.025-.889a4.902 4.902 0 001.269-1.39c.532-.83.799-1.77.799-2.819 0-1.015-.234-1.88-.698-2.59-.47-.71-1.158-1.23-2.072-1.56zM3.805 3.142h3.901c.857 0 1.564.092 2.119.275.642.267.963.809.963 1.635 0 .742-.245 1.26-.73 1.552-.487.292-1.12.439-1.895.439H3.805V3.142zm6.169 11.23c-.432.208-1.039.311-1.817.311H3.805V9.968h4.412c.769.006 1.367.107 1.795.298.761.344 1.14.975 1.14 1.898 0 1.087-.392 1.82-1.178 2.207zM26.444.896h-7.68v2.2h7.68v-2.2zM29.115 9.58c-.16-1.024-.51-1.925-1.058-2.702-.6-.881-1.361-1.526-2.288-1.934-.922-.41-1.961-.615-3.117-.613-1.942 0-3.519.607-4.739 1.813-1.216 1.209-1.825 2.947-1.825 5.213 0 2.416.672 4.162 2.025 5.233 1.346 1.073 2.902 1.608 4.667 1.608 2.137 0 3.8-.636 4.987-1.905.76-.801 1.189-1.59 1.283-2.364h-3.538c-.205.383-.442.682-.714.9-.493.398-1.135.597-1.922.597-.748 0-1.383-.165-1.912-.493-.874-.525-1.33-1.446-1.389-2.757h9.656c.015-1.13-.021-1.997-.116-2.595zm-9.453.34c.127-.85.436-1.525.925-2.023.49-.497 1.182-.747 2.068-.75.818 0 1.501.235 2.057.704.55.473.86 1.16.923 2.068h-5.973z"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://dribbble.com/thepotatostudio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg width="18" height="18" viewBox="0 0 25 25" fill="none">
                      <path
                        fill="#fff"
                        d="M22.756 6.809a11.95 11.95 0 00-4.367-4.367C16.55 1.369 14.543.832 12.366.832c-2.178 0-4.185.537-6.024 1.61a11.947 11.947 0 00-4.367 4.367C.902 8.648.365 10.656.365 12.832c0 2.178.537 4.185 1.61 6.024a11.95 11.95 0 004.367 4.367c1.839 1.073 3.846 1.61 6.023 1.61 2.177 0 4.185-.537 6.024-1.61a11.947 11.947 0 004.367-4.367c1.073-1.839 1.61-3.847 1.61-6.024s-.537-4.185-1.61-6.023zm-10.39-4.226c2.583 0 4.838.854 6.765 2.562l-.195.273c-.078.11-.274.32-.586.633-.313.312-.654.61-1.024.89-.37.282-.872.602-1.508.962-.635.36-1.312.674-2.03.945-1.199-2.208-2.48-4.198-3.845-5.969.824-.198 1.63-.296 2.422-.296zm-8.032 3.89c1-1.26 2.219-2.23 3.656-2.906 1.292 1.718 2.563 3.687 3.813 5.906-3.167.833-6.323 1.25-9.469 1.25.334-1.573 1-2.99 2-4.25zM2.803 16.528a10.055 10.055 0 01-.688-3.695c0-.146.006-.256.016-.329 3.771 0 7.276-.484 10.516-1.453.333.646.61 1.224.828 1.735-.042.02-.11.044-.203.07a9.657 9.657 0 01-.204.055l-.578.203c-.396.146-.914.396-1.555.75-.64.354-1.32.776-2.039 1.265-.718.49-1.46 1.139-2.226 1.946a13.1 13.1 0 00-1.93 2.601 10.261 10.261 0 01-1.937-3.148zm9.562 6.555c-2.437 0-4.614-.782-6.531-2.344l.234.172c.365-.802.901-1.573 1.61-2.313.708-.74 1.39-1.336 2.047-1.789.656-.453 1.37-.88 2.14-1.281.771-.401 1.297-.657 1.579-.766.28-.109.505-.195.671-.258l.031-.015h.032c1.02 2.677 1.75 5.27 2.187 7.78a10.244 10.244 0 01-4 .814zm8.626-4.711a10.24 10.24 0 01-2.89 2.96c-.428-2.395-1.094-4.838-2-7.328 2.072-.323 4.202-.171 6.39.453a10.113 10.113 0 01-1.5 3.915zm1.468-5.68c-.104-.02-.234-.044-.39-.07l-.571-.094c-.224-.037-.474-.07-.75-.101a25.75 25.75 0 00-2.961-.172c-.364 0-.747.01-1.148.03-.401.021-.79.058-1.164.11a1.861 1.861 0 01-.117-.258 5.417 5.417 0 01-.102-.273 102.94 102.94 0 00-.688-1.485 16.023 16.023 0 002.056-1.007c.65-.38 1.17-.72 1.562-1.016.39-.297.76-.612 1.11-.945.348-.334.575-.565.679-.696.104-.13.203-.258.297-.382l.016-.016c1.52 1.854 2.297 3.99 2.328 6.406l-.157-.031z"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://thepotatostudio.medium.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg width="40" height="23" fill="none" viewBox="0 0 40 23">
                      <path
                        fill="#fff"
                        d="M22.75 11.505c0 6.26-4.92 11.334-10.989 11.334-6.069 0-10.99-5.074-10.99-11.334C.771 5.246 5.691.171 11.761.171s10.99 5.075 10.99 11.334zM34.806 11.505c0 5.892-2.46 10.67-5.495 10.67s-5.495-4.778-5.495-10.67S26.276.835 29.311.835s5.495 4.777 5.495 10.67M39.738 11.505c0 5.278-.865 9.559-1.933 9.559-1.067 0-1.932-4.28-1.932-9.559 0-5.279.865-9.559 1.932-9.559s1.933 4.28 1.933 9.559z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero-clients">
        <div className="container">
          <div className="hero-clients-head">
            <p>Our clients across the globe</p>

            <div className="flex-row hero-client-list no-margin">
              <img data-watch-visibility src="/img/smisen.png" alt="smisen" />
              <img data-watch-visibility src="/img/bosch.png" alt="bosch" />
              <img data-watch-visibility src="/img/smiles.png" alt="smiles" />
            </div>
          </div>
        </div>
      </section>
      <section style={{ backgroundColor: "#17181C" }}>
        <div className="process-section-main">
          <div className="container">
            <h2
              className="orange-heading"
              data-watch-visibility
              style={{ marginBottom: 30 }}
            >
              What we do
            </h2>

            <p className="subhead" data-watch-visibility>
              Exceptional experiences are at the Potato Studio’s core. We’re a
              multi-disciplinary agency that specializes in immersive UI/UX
              design, smooth motion graphics and stellar branding. Simply put,
              we tailor make extraordinary, riveting concepts for brands that
              want more. Every time.
            </p>

            <div className="flex-row">
              <div className="col col-4" data-watch-visibility>
                <div className="single-process">
                  <img src="/img/research.png" alt="research" />
                  <div className="process-text">
                    <h3>Research</h3>
                    <p>
                      Making ideas come to life isn’t easy - but leave the
                      numbers to us. At the Potato Studio, we make immersive
                      design meet intuitive research. Magic is bound to happen.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col-4" data-watch-visibility>
                <div className="single-process mid-process">
                  <img src="/img/strategy.png" alt="Strategy" />
                  <div className="process-text">
                    <h3>Strategy</h3>
                    <p>
                      A bent rule, a box of ideas, and boundaries to cross - our
                      mind works in ways you wouldn’t expect. What you can
                      expect is outstanding, every time.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col-4" data-watch-visibility>
                <div className="single-process">
                  <img src="/img/design.png" alt="Design" />
                  <div className="process-text">
                    <h3>Design</h3>
                    <p>
                      Trends mean nothing to those that make them. And we’re a
                      team of designers that loves to create the unexpected. Our
                      results speak for themselves.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-work-main" id="portfolio">
          <div className="container">
            <h2 className="orange-heading text-center" data-watch-visibility>
              Portfolio
            </h2>
            <p className="subhead text-center" data-watch-visibility>
              We go beyond design, we make beautiful experiences.
            </p>

            <div className="single-project-area" data-watch-visibility>
              <div className="flex-row  flex-reverse-mob">
                <div className="col col-5">
                  <div className="project-text">
                    <span className="project-tag">Product design &amp; UI</span>

                    <h2>Project Ada</h2>

                    <p>
                      A fresh, perceptive design for a young educational
                      platform.
                    </p>

                    <a
                      href="https://dribbble.com/shots/15603116-Learning-Management-System"
                      className="button-arrow"
                      style={{ color: "#42B4C6" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Project{" "}
                      <svg
                        width="19"
                        height="16"
                        viewBox="0 0 19 16"
                        fill="none"
                      >
                        <path
                          fill="#42B4C6"
                          d="M18.668 8.69a1 1 0 000-1.414L12.304.912a1 1 0 10-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM.961 8.983h17v-2h-17v2z"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="col col-7">
                  <div className="project-slider">
                    <img
                      src="/img/ipad.png"
                      alt=""
                      className="project-slider-bg"
                    />
                    <Slider
                      dots
                      arrows={false}
                      cssEase="linear"
                      infinite
                      fade
                      speed={500}
                      slidesToShow={1}
                      slidesToScroll={1}
                    >
                      <div className="text-center">
                        <img src="/img/project-ada-1.png" alt="Prohject ADA" />
                      </div>
                      <div className="text-center">
                        <img src="/img/project-ada-2.png" alt="Prohject ADA" />
                      </div>
                      <div className="text-center">
                        <img src="/img/project-ada-3.png" alt="Prohject ADA" />
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="other-projects-main">
        <div className="container">
          <div className="single-project-area" data-watch-visibility>
            <div className="flex-row align-items-center">
              <div className="col col-6">
                <div className="project-slider iphone-slider project-slider-1">
                  <img
                    src="/img/iphone.png"
                    alt=""
                    className="project-slider-bg"
                  />
                  <Slider
                    dots
                    arrows={false}
                    cssEase="linear"
                    infinite
                    fade
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                  >
                    <div className="text-center">
                      <img src="/img/radio-now.png" alt="circ-fantasy" />
                    </div>
                    <div className="text-center">
                      <img src="/img/radio-now-2.png" alt="circ-fantasy" />
                    </div>
                    <div className="text-center">
                      <img src="/img/radio-now-3.png" alt="circ-fantasy" />
                    </div>
                  </Slider>
                </div>
              </div>
              <div className="col col-5 offset-1">
                <div className="project-text">
                  <span
                    className="project-tag"
                    style={{
                      background: "rgba(50, 51, 55, 0.1)",
                      color: "#17181C",
                    }}
                  >
                    Product design &amp; UI
                  </span>

                  <h2 style={{ color: "#17181C" }}>Radio now</h2>

                  <p style={{ color: "#17181C" }}>
                    A seamless, sensory design with elegance built-in.
                    Transistor-like dials make navigation familiar for a radio
                    experience, like you’ve never felt before.
                  </p>

                  <a
                    style={{ color: "#FF4F73" }}
                    href="https://dribbble.com/shots/15798916-Old-school-fm-radio-player"
                    className="button-arrow orange"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Project{" "}
                    <svg width="19" height="16" viewBox="0 0 19 16" fill="none">
                      <path
                        fill="#FF4F73"
                        d="M18.668 8.69a1 1 0 000-1.414L12.304.912a1 1 0 10-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM.961 8.983h17v-2h-17v2z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="single-project-area" data-watch-visibility>
            <div className="flex-row align-items-center  flex-reverse-mob">
              <div className="col col-5">
                <div className="project-text">
                  <span
                    className="project-tag"
                    style={{
                      background: "rgba(50, 51, 55, 0.1)",
                      color: "#17181C",
                    }}
                  >
                    Product design &amp; UI
                  </span>

                  <h2 style={{ color: "#17181C" }}>Recce</h2>

                  <p style={{ color: "#17181C" }}>
                    Contemporary, agile, and up-scale. Recce’s design philosophy
                    was crafted with care to make it easy to navigate and to
                    make exploring people and places joyful.
                  </p>

                  <a
                    href="https://dribbble.com/shots/15944543-Travel-Community-App-Recce"
                    className="button-arrow"
                    style={{ color: "#1A978D" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Project{" "}
                    <svg width="19" height="16" viewBox="0 0 19 16" fill="none">
                      <path
                        fill="#1A978D"
                        d="M18.668 8.69a1 1 0 000-1.414L12.304.912a1 1 0 10-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM.961 8.983h17v-2h-17v2z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="col col-6 offset-1">
                <div className="project-slider iphone-slider project-slider-2">
                  <img
                    src="/img/iphone.png"
                    alt=""
                    className="project-slider-bg"
                  />
                  <Slider
                    dots
                    arrows={false}
                    cssEase="linear"
                    infinite
                    fade
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                  >
                    <div className="text-center">
                      <img src="/img/recee.png" alt="recee" />
                    </div>
                    <div className="text-center">
                      <img src="/img/recee-2.png" alt="recee" />
                    </div>
                    <div className="text-center">
                      <img src="/img/recee-3.png" alt="recee" />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center more-button" data-watch-visibility>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://dribbble.com/thepotatostudio"
              className="circle-button"
            >
              <span> More</span>
            </a>
          </div>
        </div>
      </section>

      <section>
        <div className="clients-block no-anim" data-watch-visibility>
          <div className="single-client-block">
            <span>
              <img src="/img/project-ada-logo.png" alt="" />
            </span>
          </div>
          <div className="single-client-block">
            <span>
              <img src="/img/byrds.svg" alt="" />
            </span>
          </div>
          <div className="single-client-block">
            <span>
              <img src="/img/toulo.svg" alt="" />
            </span>
          </div>
          <div className="single-client-block">
            <span>
              <img src="/img/defi.svg" alt="" />
            </span>
          </div>
          <div className="single-client-block">
            <span>
              <img src="/img/komect.svg" alt="" />
            </span>
          </div>
          <div className="single-client-block">
            <span>
              <img src="/img/bosch.png" alt="" />
            </span>
          </div>
        </div>

        {/* <div className="container">
          <div className="footer-cta" data-watch-visibility>
            <h2>
              Okay then, <br />
              Let’s make things <span className="orange">together!</span>
            </h2>
            <Link to="/" className="button">
              Ping us
            </Link>
          </div>
        </div> */}
      </section>

      <section className="testimonial-main">
        <Slider {...settingsTestimonial}>
          <div className="single-testimonial">
            <div className="container">
              <div className="quote">
                <img src="/img/quote.svg" alt="" />
              </div>

              <div className="testimonial-desc">
                <p>
                  The Potato Studio has extremely talented and creative people
                  as a part of their roster. My experience with the team was
                  nothing short of magnificent! New ideas, responsiveness to
                  feedback - they all came together to deliver high quality
                  results without hurdles!
                </p>
                <p>
                  Timely meetings, idea exchanges and effortless creativity - I
                  would highly recommend Potato studio for their thoughtful
                  process to get each project done.
                </p>
              </div>
              <div
                className="flex-row no-margin align-items-center"
                style={{ flexWrap: "nowrap" }}
              >
                <div className="testimonial-image">
                  <img src="/img/mukundh.png" alt="" />
                </div>

                <div className="author">
                  <h4>Mukundh Bhushan</h4>
                  <p>Founder &amp; CEO, Nebutech Analytics</p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-testimonial orange-testimonial">
            <div className="container">
              <div className="quote">
                <img src="/img/quote.svg" alt="" />
              </div>

              <div className="testimonial-desc">
                <p>
                  Working with the Potato Studio, one thing was clear - their
                  knowledge is unparalleled. Their understanding of our needs
                  and incisive expertise came together to make our product
                  better than before. Their creativity fueled my vision for a
                  simplistic, yet impactful website, and made it come to life.
                  The team was responsive to feedback, and was quick to
                  incorporate it.
                </p>
                <p>
                  If you’re looking for a design studio that is upfront, honest,
                  and above all - knows their business, look no further than
                  Potato studio!
                </p>
              </div>
              <div
                className="flex-row no-margin align-items-center"
                style={{ flexWrap: "nowrap" }}
              >
                <div className="testimonial-image">
                  <img src="/img/sidd.png" alt="" />
                </div>

                <div className="author">
                  <h4>Sidd Partha</h4>
                  <p>CEO, ProjectAda</p>
                </div>
              </div>
            </div>
          </div>
        </Slider>

        <div className="container">
          <div className="our-culture" data-watch-visibility>
            <p>NEXT UP</p>
            <h2>Check out our culture</h2>
            <Link to="/about/" className="button-arrow">
              <svg width="19" height="16" viewBox="0 0 19 16" fill="none">
                <path
                  fill="#fff"
                  d="M18.668 8.69a1 1 0 000-1.414L12.304.912a1 1 0 10-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM.961 8.983h17v-2h-17v2z"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </section>
      <div className="footer-cta">
        <div className="container">
          <div className="flex-row align-items-center" data-watch-visibility>
            <div className="col col-6">
              <h2>
                Take the next step.
                <span className="orange"> Let’s talk.</span>
              </h2>
            </div>
            <div className="col col-6">
              <a
                href="mailto:hello@thepotatostudio.co.in"
                className="flex-row footer-cta-block no-margin align-items-center"
              >
                <div>
                  <p>Write us at</p>
                  <h4>hello@thepotatostudio.co.in</h4>
                </div>
                <span className="button-arrow">
                  <svg width="19" height="16" viewBox="0 0 19 16" fill="none">
                    <path
                      fill="#fff"
                      d="M18.668 8.69a1 1 0 000-1.414L12.304.912a1 1 0 10-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM.961 8.983h17v-2h-17v2z"
                    ></path>
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
